<template>
  <div class="news-container">
    <div class="top">
      <div class="min-size">
      
      </div>
    </div>

    <div class="title-block">
      <div class="min-size">
        <div class="title">县域普惠合作伙伴招募令</div>
        <div class="create-at">2020-08-07 17:20:36</div>
      </div>
    </div>

    <div class="content-block">
      <div class="content">
        <div class="min-size">
          <div style="font-size: 16px;color: #333333;line-height: 32px;">
            <p style="font-weight:600;padding:0;margin:0">数字普惠金融，助力乡村振兴。</p>
            2021年中央一号文件首次提出“发展农村数字普惠金融”。作为首批互联网银行，使用网商银行信贷服务的用户达4000万+。
            “县域普惠金融服务”是网商银行基于多年实践积累的互联网大数据技术和风控能力，自主研发的金融产品。自2018年开展普惠金融业务以来，网商银行已覆盖800余县，获得政府的多项支持和反馈，可实现“3分钟申请，1秒钟放款，0人工干预”，按日计息，随借随还，不收提前还款手续费。
            <p style="font-weight:600;padding:0;margin:0;text-align:center">一起加入到县域普惠金融大团队，成为我们优秀的合作伙伴吧！</p>

            <p style="font-weight:600;padding:0;margin:0;margin-top:30px">招募标淮：</p>
            <p style="padding:0;margin:0">1.具备一定的政企合作经验，熟悉政府事务处理流程，具有较强的政策意识。</p>
            <p style="padding:0;margin:0">2.熟悉当地经济文化和农业产业，热爱井愿意积极投身农村事业。</p>
            3.企业资质
            <p style="padding:0;margin:0;text-indent:2em">(1)提供服务主体的工商注册在有效期内且满一年，企业主体及实控人无被执行人信息、大额未决诉讼及重大负面舆情信息；</p>
            <p style="padding:0;margin:0;text-indent:2em">(2)主营业务模式清晰，发展健康，具有较强的经营实力；</p>
            <p style="padding:0;margin:0;text-indent:2em">(3)具有完善的内部管理和风控机制。</p>
            4. 优先条件
            <p style="padding:0;margin:0;text-indent:2em">(1)有与网商银行、阿里集团或蚂蚁集团合作经验的企业优先；</p>
            <p style="padding:0;margin:0;text-indent:2em">(2)有金融业务或互联网业务的合作伙伴优先。</p>
            <p style="font-weight:600;padding:0;margin:0;">合作职责：</p>
            <p style="padding:0;margin:0">1.开拓并维护县域渠道关系；</p>
            <p style="padding:0;margin:0">2.协助业务落地推广运营；</p>
            <p style="padding:0;margin:0">3.定期进行业务汇报；</p>
            <p style="padding:0;margin:0">4.协助金融创新。</p>
            <p style="font-weight:600;padding:0;margin:0;">服务模式：</p>
            合作方式详情可加微信或邮件咨询。
            <p style="font-weight:600;padding:0;margin:0;">招募区域：</p>
            全国
            <p style="font-weight:600;padding:0;margin:0;">联系人：</p>
            普惠金融事业部黎江成 lijiangchengyinfang@dingtalk
            </br>
            <img style="width:150px;border:1px dashed #000;margin-top:20px" src="@/assets/yf/ljc.png" alt="image">

            <!-- <p style="padding:0;margin:0;margin-top:30px">招募详情如有疑问可咨询</p>
            <p style="padding:0;margin:0">农村县域金融合作部李要雯 xunyou.lww@mybank.cn</p> -->
          </div>

           <!-- <img style="width:150px;border:1px dashed #000;margin-top:20px" src="@/assets/yf/zhaomu.png" alt="image"> -->
        </div>
      </div>
    </div>
  </div>
</template>



<script>
const defaultSettings = require('@/settings.js')
export default {
  name: 'zhaomulist',
  components: {
  },
  data(){
    return {
      title:'',
      content:'',
      createTime:'',
    }
  },
  mounted () {
 
  },
  destroyed () {
  },
  created () {
  },
  methods: {
   
  }
}
</script>

<style lang="less">
.news-container {
  .top {
    padding-top: 88px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    .min-size {
      width: 1200px;

      .back {
        font-size: 14px;
        color: #666666;
        text-decoration: none;
        display: flex;
        align-items: center;

        .icon-arrow-left {
          font-size: 24px;
        }
      }
    }
  }

  .title-block {
    width: 100%;
    height: 220px;
    background: #f3f7fa;
    display: flex;
    justify-content: center;

    .min-size {
      width: 900px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        font-size: 36px;
        color: #333333;
        line-height: 40px;
      }

      .create-at {
        padding-top: 25px;
        font-size: 14px;
        color: #999999;
        line-height: 20px;
      }
    }
  }

  .content-block {
    display: flex;
    justify-content: center;
    padding: 80px 0;

    .content {
      display: flex;
      justify-content: center;
      width: 1200px;

      .min-size {
        width: 900px;
      }
    }
  }
}
</style>
